

















































import { Component, Vue, Prop } from "vue-property-decorator";
import { getGanyuDetail } from "@/request/doctor";
@Component({})
export default class Name extends Vue {
  private data: any = {};
  private type: any = "add";
  private get patient() {
    return this.$store.state.patient;
  }
  private getData() {
    const params = {
      intervene_id: this.data.intervene_id,
      source: "pc医生",
    };
    getGanyuDetail(this, params).then((res: any) => {
      this.data = res;
    });
  }
  private goEdit() {
    this.$router.push({
      path: "/main/workstation/patient-ganyu-edit",
      query: {
        intervene_id: this.data.intervene_id,
      },
    });
  }
  mounted() {
    if (this.$route.query.type) {
      this.type = "read";
    } else {
      this.type = "add";
    }
    if (this.$route.query.intervene_id) {
      this.data.intervene_id = this.$route.query.intervene_id;
      this.getData();
    }
  }
}
